<template>
    <div class="card">
        <div class="container">
            <div class="title">
                <!-- <h3 v-if="this.data.attributes.field_article_type!=null" class="article-type">{{articleType}}</h3> -->
                <h2>{{ data.attributes.field_article_newspaper }}</h2>
                <p class="data">{{ this.convertDate }}</p>
            </div>
            <div class="content"  v-if="data.attributes.title">
                {{ data.attributes.title }}
            </div>
            <div class="links">
                <a  v-if="extlink != '#'"
                    :href="extlink" target="_blank" class="cta-link">
                    {{ this.lang=='it' ? 'Vedi online' : 'View online' }}
                    <span><img src="../assets/icon-arrow.svg" alt="link"></span>
                </a>
                <a  v-if="docsrc != '#'"
                    :href="docsrc" download target="_blank">
                    {{ this.lang=='it' ? 'Scarica articolo' : 'Download the article' }}
                    <span><img src="../assets/icon-download.svg" alt="download"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { reWriteDate } from '../libs/utils'
export default {
    name: 'article-tile',
    data: () => {
        return {

        }
    },
    props: {
        data: Object
    },
    computed: {
        articleType(){
            //console.log('this.data.attributes.field_article_type',this.data.attributes.field_article_type);
            if (this.data.attributes.field_article_type == 0 )
                return "edizione web"
            else
                return "edizione cartacea"
        },
        extlink() {
            if(this.data.attributes && this.data.attributes.field_article_link)
                return this.data.attributes.field_article_link.uri
            else 
                return '#'
        },
        docsrc() {
            if(this.data.field_article_attachment)
                return process.env.VUE_APP_ENDPOINT + this.data.field_article_attachment.attributes.uri.url
            else 
                return '#'
        },
        convertDate() {
            return reWriteDate(this.data.attributes.field_article_date)
        },
        lang() {
            return this.$store.state.currentLang
        }
    }
}
</script>

<style lang="scss" scoped>
.article-type {
    font-size: 0.6875rem;
    font-family: "poppins";
    text-transform: uppercase;
    font-weight: 100;
    color: #666;
    letter-spacing: 0.1rem;
}
</style>