<template>
    <basic-page class="rassegna-stampa" :pagedata="this.pageData" bannerimg="/img/rassegna-stampa-banner.jpg">
        <div class="article-container container-beige container">
            <transition-group name="list" tag="div">
                <article-tile v-for="(p, index) in this.pressData" :key="'press'+index" :data="p"></article-tile>
            </transition-group>
        </div>
    </basic-page>
</template>

<script>
    import ArticleTile from '../components/ArticleTile.vue'
    import BasicPage from './BasicPage.vue'
    import {
        fetchSinglePage,
        fetchNodes,
        getTranslation
    } from '../libs/drupalClient'
    export default {
        components: {
            ArticleTile,
            BasicPage
        },
        name: 'rassegna-stampa',
        data: () => {
            return {
                currentPageId: "b7692970-a337-4c14-b4ea-37dda11a6fcd",
                pageData: {
                    attributes: {}
                },
                pressData: []
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageId, this.lang).then(res => {
                this.pageData = res[0]
            })
            fetchNodes('article', {
                include: ['field_article_attachment']
            }, this.lang).then(res => {
                //console.log('r',res);
                this.pressData = res.sort((a, b) => {
                    return a.attributes.field_article_date > b.attributes
                        .field_article_date ? -1 : 1
                })
            })
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang)
                .then(res => {
                    this.$router.push({path: res})
                })

                fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                }) 

                fetchNodes('article', {
                    include: ['field_article_attachment']
                }, this.lang).then(res => {
                    //console.log('r',res);
                    this.pressData = res.sort((a, b) => {
                        return a.attributes.field_article_date > b.attributes
                            .field_article_date ? -1 : 1
                    })
                })
            }
        }
    }
</script>

<style lang="scss">
    @import "../styles/colors.scss";

    .rassegna-stampa {
        margin-bottom: 3rem;

        .container-beige {
            padding: 2rem 2rem;
            background-color: #fff;
        }

        .card .container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            background: #fff;
            margin: 20px auto;
            padding: 40px 2rem;
            border-bottom: 16px solid #f0eae6;

            .title {
                font-size: 1.625rem;
                line-height: 2.54rem;
                width: 30%;

                .data {
                    margin-top: 30px;
                }

                border-right: 1px #eee solid;
            }

            .content {
                margin-left: 10px;
                padding-left: 40px;
                width: 45%;
                border-left: 1px #eee solid;
            }

            .links {
                margin-left: 3rem;
                width: 20%;
                text-align: right;
                display: flex;
                flex-direction: column;
            }

            span {
                display: inline-block;
                width: 50px;
            }

            .cta-link {
                color: $mainColor;
            }

            a {
                text-decoration: none;
                margin-bottom: 1rem;
            }
        }
    }

    @media (max-width:1500px) {
        .rassegna-stampa .card .container{

        .title {
            width: 40%;
            font-size: 1.375rem;
            line-height: 2.3rem;
        }

        .content {
            width: 35%;
        }

        .links {
            width: 20%;
        }
        }
    }

    @media (max-width:1200px) {
        .rassegna-stampa .card .container {
            padding: 40px 1rem;
            flex-direction: column;

            .title,
            .content,
            .links {
                width: auto;
                border: 0px;
            }

            .content {
                padding-left: 0;
                margin: 1rem 0rem 3rem 0rem;
            }

            .links {
                margin: 0;
                display: flex;
                flex-direction: column;
            }
        }
    }

    @media (max-width:992px) {
        .rassegna-stampa .card .container {
            padding: 40px 3rem;
        }
    }
    @media (max-width:768px) {
        .rassegna-stampa .card .container {
            padding: 20px 0rem;
        }
        .rassegna-stampa .card .container .title {
            font-size: 1.125rem;
            line-height: 1.9rem;
        }
        .rassegna-stampa .container-beige {
    padding: 2rem 15px;
        }
    }
</style>